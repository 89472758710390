import React from 'react'
import { Switch } from 'react-router-dom'
import Clients from '../../pages/Clients'
import Audit from '../../pages/Audit'
import Route from '../Route'
import Mechanics from '../../pages/Mechanics'
import ClientDetailsPage from '../../pages/Clients/ClientDetailsPage'
import MechanicDetailsPage from '../../pages/Mechanics/MechanicsDetailsPage'
import MechanicIndications from '../../pages/MechanicIndications'
import Notifications from '../../pages/Notifications'
import Promotions from '../../pages/Promotions'
import Dashboard from '../../pages/Dashboard'
import GasStationsPrices from '../../pages/GasStationsPrices'

const SubRoutes = () => {
    return (
        <Switch>
            {/* never fucking puts another main component on routes of another main component, it will be fire everywhere */}
            <Route path="/dashboard" isPrivate exact component={Dashboard} />
            <Route path="/dashboard/clientes" isPrivate exact component={Clients} />
            <Route
                path="/dashboard/clientes/detalhes"
                isPrivate
                exact
                component={ClientDetailsPage}
            />
            <Route
                path="/dashboard/mecânicas/detalhes"
                isPrivate
                exact
                component={MechanicDetailsPage}
            />
            <Route path="/dashboard/mecânicas" isPrivate exact component={Mechanics} />
            <Route
                path="/dashboard/notificações"
                exact
                isPrivate
                component={Notifications}
            />
            <Route
                path="/dashboard/preços-postos-de-gasolina"
                exact
                isPrivate
                component={GasStationsPrices}
            />
            <Route path="/dashboard/promoções" exact isPrivate component={Promotions} />
            <Route path="/dashboard/auditoria" exact isPrivate component={Audit} />
            <Route
                path="/dashboard/indicações"
                exact
                isPrivate
                component={MechanicIndications}
            />
        </Switch>
    )
}

export default SubRoutes
