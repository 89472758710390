import React, { useEffect, useState } from 'react'

import { Container } from './styles'
import Box from '@mui/material/Box'
import { Card, TextField, Typography, Grid, Button } from '@mui/material'
// import { useModalContext } from '../../contexts/ModalContext'
import 'react-data-table-component-extensions/dist/index.css'
import * as MechanicsService from '../../services/mechanics'

const GasStationsPrices = () => {
    // const { setModal } = useModalContext()
    const [data, setData] = useState([])
    const [prices, setPrices] = useState({})
    const [message, setMessage] = useState('')
    const [messageType, setMessageType] = useState('')

    useEffect(() => {
        fetchTableData()
    }, [])

    const showMessage = (text, type) => {
        setMessage(text)
        setMessageType(type)
        setTimeout(() => {
            setMessage('')
            setMessageType('')
        }, 3000)
    }

    async function fetchTableData() {
        try {
            const response = await MechanicsService.getAllPriceImages()
            console.log(response)
            setData(response)
            // Initialize prices state for each establishment
            const initialPrices = {}
            response.forEach((establishment) => {
                const priceValues = {
                    gasoline: '',
                    aditivada: '',
                    diesel: '',
                    alcohol: '',
                    gnv: '',
                }
                // Set initial values from existing prices
                establishment.prices?.forEach((price) => {
                    if (price.description === 'GASOLINE') {
                        priceValues.gasComum = price.price.toString().replace(',', '.')
                    }
                    if (price.description === 'ADITIVADA') {
                        priceValues.gasAditivada = price.price
                            .toString()
                            .replace(',', '.')
                    }
                    if (price.description === 'DIESEL') {
                        priceValues.diesel = price.price.toString().replace(',', '.')
                    }
                    if (price.description === 'ALCOHOL') {
                        priceValues.etanol = price.price.toString().replace(',', '.')
                    }
                    if (price.description === 'GNV') {
                        priceValues.gnv = price.price.toString().replace(',', '.')
                    }
                })

                initialPrices[establishment.establishmentId] = priceValues
            })
            setPrices(initialPrices)
        } catch (error) {
            console.error('Error fetching data:', error)
            showMessage('Erro ao carregar dados!', 'error')
        }
    }

    const handlePriceChange = (establishmentId, type, value) => {
        // Ensure value uses dot as decimal separator
        const formattedValue = value.replace(',', '.')
        setPrices((prev) => ({
            ...prev,
            [establishmentId]: {
                ...prev[establishmentId],
                [type]: formattedValue,
            },
        }))
    }

    const handleSubmit = async (establishmentId) => {
        const establishmentPrices = prices[establishmentId]
        const pricesList = [
            {
                description: 'GASOLINE',
                price: parseFloat(establishmentPrices.gasComum),
            },
            {
                description: 'ADITIVADA',
                price: parseFloat(establishmentPrices.gasAditivada),
            },
            { description: 'ALCOHOL', price: parseFloat(establishmentPrices.etanol) },
            { description: 'DIESEL', price: parseFloat(establishmentPrices.diesel) },
            { description: 'GNV', price: parseFloat(establishmentPrices.gnv) },
        ].filter((price) => !isNaN(price.price))

        if (pricesList.length === 0) {
            showMessage('Por favor, insira pelo menos um preço válido!', 'error')
            return
        }

        try {
            await MechanicsService.savePriceImage(establishmentId, pricesList)
            showMessage('Preços atualizados com sucesso!', 'success')

            // Remove the establishment from data after successful update
            setData((prevData) =>
                prevData.filter((est) => est.establishmentId !== establishmentId),
            )
        } catch (error) {
            console.error('Error saving prices:', error)
            showMessage('Erro ao atualizar preços. Tente novamente!', 'error')
        }
    }

    return (
        <Container>
            <Box
                mb={3}
                fontWeight="bold"
                fontSize={{ xs: 'h7.fontSize', sm: 'h6.fontSize', md: 'h5.fontSize' }}
            >
                Avaliação de Preços dos Postos de Gasolina
            </Box>
            <Box
                mb={3}
                fontSize={{ xs: 'h9.fontSize', sm: 'h9.fontSize', md: 'h8.fontSize' }}
            >
                <b>ATENÇÃO!!</b> Avalie com atenção os preços abaixo dos postos de
                gasolina, cada alteração impactará diretamente o cliente final!
            </Box>
            {message && (
                <Box
                    sx={{
                        position: 'fixed',
                        top: 30,
                        left: '50%',
                        transform: 'translateX(-50%)',
                        backgroundColor:
                            messageType === 'success' ? '#4caf50' : '#f44336',
                        color: 'white',
                        padding: '10px 20px',
                        borderRadius: '4px',
                        zIndex: 9999,
                    }}
                >
                    {message}
                </Box>
            )}
            <Box mt={3} mb={5}>
                {data.map((establishment) => (
                    <Card key={establishment.establishmentId} sx={{ mb: 3, p: 3 }}>
                        <Grid container spacing={3}>
                            <Grid item xs={12} md={6}>
                                <Typography variant="h6" gutterBottom>
                                    {establishment.establishmentName}
                                </Typography>
                                <Typography variant="body1" gutterBottom>
                                    {establishment.establishmentAddress}
                                </Typography>
                                <img
                                    src={establishment.imageUrl}
                                    alt="Preços do posto"
                                    style={{
                                        width: '100%',
                                        height: 'auto',
                                        maxHeight: '400px',
                                        objectFit: 'contain',
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <Grid container spacing={2}>
                                    <Grid item xs={12} sm={6}>
                                        <TextField
                                            fullWidth
                                            label="Gas. Comum"
                                            type="number"
                                            value={
                                                prices[establishment.establishmentId]
                                                    ?.gasComum
                                            }
                                            onChange={(e) =>
                                                handlePriceChange(
                                                    establishment.establishmentId,
                                                    'gasComum',
                                                    e.target.value,
                                                )
                                            }
                                            inputProps={{
                                                step: 0.01,
                                                min: 0,
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <TextField
                                            fullWidth
                                            label="Gas. Aditivada"
                                            type="number"
                                            value={
                                                prices[establishment.establishmentId]
                                                    ?.gasAditivada
                                            }
                                            onChange={(e) =>
                                                handlePriceChange(
                                                    establishment.establishmentId,
                                                    'gasAditivada',
                                                    e.target.value,
                                                )
                                            }
                                            inputProps={{
                                                step: 0.01,
                                                min: 0,
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <TextField
                                            fullWidth
                                            label="Etanol"
                                            type="number"
                                            value={
                                                prices[establishment.establishmentId]
                                                    ?.etanol
                                            }
                                            onChange={(e) =>
                                                handlePriceChange(
                                                    establishment.establishmentId,
                                                    'etanol',
                                                    e.target.value,
                                                )
                                            }
                                            inputProps={{
                                                step: 0.01,
                                                min: 0,
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <TextField
                                            fullWidth
                                            label="Diesel"
                                            type="number"
                                            value={
                                                prices[establishment.establishmentId]
                                                    ?.diesel
                                            }
                                            onChange={(e) =>
                                                handlePriceChange(
                                                    establishment.establishmentId,
                                                    'diesel',
                                                    e.target.value,
                                                )
                                            }
                                            inputProps={{
                                                step: 0.01,
                                                min: 0,
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <TextField
                                            fullWidth
                                            label="GNV"
                                            type="number"
                                            value={
                                                prices[establishment.establishmentId]?.gnv
                                            }
                                            onChange={(e) =>
                                                handlePriceChange(
                                                    establishment.establishmentId,
                                                    'gnv',
                                                    e.target.value,
                                                )
                                            }
                                            inputProps={{
                                                step: 0.01,
                                                min: 0,
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            fullWidth
                                            onClick={() =>
                                                handleSubmit(
                                                    establishment.establishmentId,
                                                )
                                            }
                                        >
                                            Atualizar Preços
                                        </Button>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Card>
                ))}
                {data.length === 0 && (
                    <Card sx={{ mb: 3, p: 3 }}>
                        <Typography variant="h6" align="center">
                            Nenhuma imagem de preços a analisar no momento
                        </Typography>
                    </Card>
                )}
            </Box>
        </Container>
    )
}

export default GasStationsPrices
