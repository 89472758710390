import React from 'react'
import ReactDOM from 'react-dom'
import './index.css'
import { HashRouter } from 'react-router-dom'
import AppProvider from './hooks'
import MainRoutes from './routes'

ReactDOM.render(
    <React.StrictMode>
        <AppProvider>
            <HashRouter>
                <MainRoutes />
            </HashRouter>
        </AppProvider>
    </React.StrictMode>,
    document.getElementById('root'),
)
