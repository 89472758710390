import React, { useEffect, useState } from 'react'

import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Card from '@mui/material/Card'
import { Container } from './styles'
import * as ClientsService from '../../services/clients'
import { Typography } from '@mui/material'
import ManageSearchIcon from '@mui/icons-material/ManageSearch'
import { useHistory } from 'react-router-dom'
import Table from '../../components/ui/Table'
import SettingsIcon from '@mui/icons-material/Settings'
import GenericModal from '../../components/ui/GenericModal'
import { Warning } from '@mui/icons-material'
import ConfirmationModal from '../../components/ui/ConfirmationModal'
import ScreenLoading from '../../components/ui/ScreenLoading'

var moment = require('moment')

const Clients = () => {
    const [data, setData] = useState([])
    const history = useHistory()

    const [configModal, setConfigModal] = useState(false)
    const [confirmationModal, setConfirmationModal] = useState({
        open: false,
        choice: 'none',
    })

    //pagination states
    const [screenLoading, setScreenLoading] = useState(false)
    const [tableLoading, setTableLoading] = useState(false)
    const [totalRows, setTotalRows] = useState(0)
    const [page, setPage] = useState(0)
    const [perPage, setPerPage] = useState(10)
    const [sortDirection, setSortDirection] = useState('ASC')
    const [sortBy, setSortBy] = useState('name')
    const [filterText, setFilterText] = useState('')

    useEffect(() => {
        async function fetchTableData() {
            setTableLoading(true)
            const response = await ClientsService.getAllClients(
                page,
                perPage,
                sortBy,
                sortDirection,
                filterText,
            )
            setData(response.content)
            setTotalRows(response.totalElements)
            setTableLoading(false)
        }

        fetchTableData() //fetch page 0 of sales
    }, [page, perPage, sortDirection, sortBy, filterText])

    const columns = [
        {
            name: 'ID',
            selector: (row) => row.uniqueIdentifier,
        },
        {
            id: 'name',
            name: 'Nome',
            selector: (row) => row.name,
            sortable: true,
        },
        {
            name: 'Email',
            selector: (row) => row.email,
        },
        {
            id: 'cpf',
            name: 'CPF',
            selector: (row) => row.cpf,
        },
        {
            name: 'Telefone',
            selector: (row) => row.phone,
        },
        {
            id: 'createdDate',
            name: 'Data de Criação',
            selector: (row) => moment(row.createdDate).local().format('DD/MM/YYYY'),
            sortable: true,
        },
        {
            name: 'Detalhes',
            cell: (row) => (
                <Button
                    id={row.ID}
                    variant="outlined"
                    onClick={() =>
                        history.push({
                            pathname: '/dashboard/clientes/detalhes',
                            state: { client: row },
                        })
                    }
                >
                    <ManageSearchIcon />
                </Button>
            ),
            ignoreRowClick: true,
            allowOverflow: true,
            button: true,
        },
    ]

    const handleRefreshAllMechanics = async () => {
        setScreenLoading(true)
        await ClientsService.refreshAllMechanicClients()
            .then((x) => {
                setScreenLoading(false)
                setConfirmationModal({ open: false, choice: 'none' })
                setConfigModal(false)
            })
            .catch((x) => {
                alert(
                    'Erro ao tentar atualizar as relações, verifique a integridade do banco de dados imediatamente!',
                )
                setScreenLoading(false)
            })
            .finally(() => {
                alert('Todas as relações foram atualizadas!')
            })
    }

    return (
        <Container>
            <Box
                mb={3}
                fontWeight="bold"
                fontSize={{ xs: 'h7.fontSize', sm: 'h6.fontSize', md: 'h5.fontSize' }}
            >
                Clientes - Usuários
            </Box>
            <Box display="flex" justifyContent="space-between" paddingBottom="20px">
                <Typography
                    fontSize={{ xs: 'h7.fontSize', sm: 'h6.fontSize', md: 'h6.fontSize' }}
                >
                    Total: <b>{totalRows} Usuários</b>
                </Typography>
                <Button
                    variant="outlined"
                    color="primary"
                    style={{ marginRight: '4px' }}
                    onClick={() => setConfigModal(true)}
                >
                    <SettingsIcon /> &nbsp; Configurações
                </Button>
            </Box>
            <Box mb={5}>
                <Card>
                    <Table
                        columns={columns}
                        data={data}
                        isPaginated={true}
                        filterLabel="Filtrar por Nome"
                        setPage={setPage}
                        setPerPage={setPerPage}
                        setSortBy={setSortBy}
                        setSortDirection={setSortDirection}
                        filterText={filterText}
                        setFilterText={setFilterText}
                        totalRows={totalRows}
                        loading={tableLoading}
                    />
                </Card>
            </Box>
            <GenericModal
                open={configModal}
                setOpen={setConfigModal}
                title="Configurações Gerais"
            >
                <Box display="flex" flexDirection="column" gap={1}>
                    <Button
                        variant="contained"
                        color="warning"
                        onClick={() =>
                            setConfirmationModal({ open: true, choice: 'remove' })
                        }
                    >
                        <Warning />
                        &nbsp; Recalcular Mechanicas-Cliente &nbsp;
                        <Warning />
                    </Button>
                    <Typography fontSize={14}>
                        * Recalcular todas as relações mecânica-cliente
                    </Typography>
                </Box>
            </GenericModal>
            <ConfirmationModal
                open={confirmationModal.open}
                setOpen={setConfirmationModal}
                title="Ação arriscada!!!"
                subText="Esta ação irá recalcular todas as relações mecânica-clientes, com o objetivo de preencher alguma relação faltante, evitando futuros bugs, porém é uma ação que mexerá em toda tabela de relações mecânica-cliente."
                handleConfirmation={() => handleRefreshAllMechanics()}
            />
            {screenLoading && <ScreenLoading />}
        </Container>
    )
}

export default Clients
